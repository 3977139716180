body {
  background: #fbdd74;
  color: #294910;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  margin: 0;
  position: relative;
  text-rendering: optimizeLegibility;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

h1 {
  font-size: 10vw;
  line-height: 1.4;
  margin: 0 20px;
}

h2 {
  bottom: 0;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 20px 20px;
  position: fixed;
}

p {
  font-size: 18px;
}

section {
  display: flex;
  height: 100vh;
  justify-content: space-between;
}

figure {
  margin: 0;
  position: relative;
  width: 50vw;
}

figcaption {
  bottom: 0;
  color: #fff;
  font-size: 36px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1;
  padding: 20px 0;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

.display {
  opacity: 1;
}

.hide {
  opacity: 0;
}

aside {
  height: 100%;
  position: absolute;
  width: 100%;
  background: #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.loading-bar {
  display: grid;
  grid-row-gap: 12px;
}

progress[value]::-webkit-progress-bar {
  background: #e8e8e8;
}

progress[value]::-webkit-progress-value {
  background: #294910;
}

progress {
  border: 1px solid #294910;
  height: 20px;
  width: 400px;
}

@media (max-width: 800px) {
  section {
    flex-direction: column;
  }

  figure {
    flex-grow: 1;
    width: 100vw;
  }

  figcaption {
    font-size: 30px;
  }

  h2 {
    font-size: 20px;
    position: relative;
  }

  h1 {
    font-size: 80px;
  }
}
